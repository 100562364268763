<script lang="ts" setup>
import type { Ref, WritableComputedRef } from 'vue'
import type { List, ListItem } from '~/types/custom'

const props = defineProps<{
  options: List
  option?: ListItem
  listType?: 'theme' | 'locale' | undefined
  fluid?: boolean
}>()

const emits = defineEmits<{ (e: 'update:option', value: ListItem): void }>()

const { t } = useI18n()

const dropdownStatus: Ref<boolean> = ref(false)

const optionModel: WritableComputedRef<ListItem | null> = computed({
  get: () => props?.option || null,
  set(option: ListItem | null): void {
    if (option) emits('update:option', option)
  },
})

const options: WritableComputedRef<Array<ListItem>> = computed((): ListItem[] => {
  const options: ListItem[] = props.options

  const activeIndex: number = options?.findIndex((option: ListItem) => option?.id === optionModel?.value?.id)

  const old: ListItem | null = options?.[activeIndex] || null

  if (!!old && !!options[0]) {
    options[activeIndex] = options[0]
    options[0] = old
  }
  return options
})

function getOptionClasses(index: number): { [x: string]: boolean } {
  return {
    'shadow-lg': dropdownStatus.value,
    hidden: !dropdownStatus.value && index !== 0,
    selected: index === 0,
    'justify-center': !optionModel?.value?.iconComponent,
    'justify-between': !!optionModel?.value?.iconComponent,
    'bg-light-200 border-dark-700 dark:bg-dark-900 hover:bg-white dark:hover:bg-black hover:border-secondary':
      index % 2 === 0,
    'bg-light-300 border-dark-700 dark:bg-dark-800 hover:bg-white dark:hover:bg-black hover:border-secondary':
      index % 2 !== 0,
  }
}

function toggleDropdown(): void {
  dropdownStatus.value = !dropdownStatus.value
}

function closeDropdown(): void {
  dropdownStatus.value = false
}

function getLocaleAttributes(locale?: string) {
  return props.listType === 'locale'
    ? {
        rel: 'alternate',
        hreflang: locale,
        'data-test': `locale-${locale}`,
        title: t('layout.anchors.switch-locale.title', { locale }),
        'aria-label': t('layout.anchors.switch-locale.aria-label', { locale }),
      }
    : {}
}

function getThemeAttributes(theme?: string) {
  return props.listType === 'theme' && theme
    ? {
        'data-theme': theme,
        'data-test': `theme-${theme}`,
        title: t('layout.anchors.switch-theme.title', { theme }),
        'aria-label': t('layout.anchors.switch-theme.aria-label', { theme }),
      }
    : {}
}
</script>

<template lang="pug">
ul.dropdown(
  v-click-outside="closeDropdown"
  :class="!fluid ? 'max-w-[250px]' : ''"
  @click="toggleDropdown"
)
  li.dropdown__option(
    v-for="(opt, index) in options",
    :key="`selection-${opt}-${index}`",
    :style="{ transform: dropdownStatus ? `translateY(${index * 9}0%)` : '' }",
    :class="getOptionClasses(index)",
    @click="optionModel = opt"
  )
    NuxtLink(
      v-if="opt?.target && index !== 0"
      v-bind="{ ...getLocaleAttributes(opt?.title), ...getThemeAttributes(opt?.title) }"
      :to="opt?.target"
    ) {{ opt?.textContent }}
      Component(
        :is="opt.iconComponent"
        v-if="opt.iconComponent !== null"

      )
    div(v-else)
      span {{ opt?.textContent }}
      Component(
        :is="opt.iconComponent"
        v-if="opt.iconComponent !== null"
      )
</template>

<style lang="sass" scoped>
ul.dropdown
  @apply w-full flex items-center text-primary rounded-md shadow-lg relative cursor-pointer min-w-[150px] xl:min-w-[175px]
  li.dropdown__option
    @apply w-full flex items-center rounded-md gap-3 absolute top-0 left-0 right-0 bottom-0 z-10 border
    transition: all 0.5s
    &.selected
      @apply relative translate-y-0 z-20
    &.hidden
      z-index: -1
      opacity: 0
      transform: translateY(90%)
    & > *
      @apply p-2
    a, & > div
      @apply w-full flex items-center justify-between gap-3
</style>
